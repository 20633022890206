import { string, arrayOf, shape, instanceOf } from 'prop-types';

export * from 'prop-types';

export const showType = {
  title: string,
  hosts: arrayOf(
    shape({
      Name: string,
    }),
  ),
  broadcast_start_time: instanceOf(Date),
  broadcast_end_time: instanceOf(Date),
  description_en: string,
  description_fr: string,
  mixcloud_slug: string,
  image: string,
  id: string,
  tags: arrayOf(
    shape({
      name_en: string,
      name_fr: string,
    }),
  ),
  languages: arrayOf(
    shape({
      name: string,
    }),
  ),
};
