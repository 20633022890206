import React, { useEffect } from 'react';
import Page from '../organisms/Page';
import Show from '../molecules/Show';
import { format } from 'date-fns';
import ContentsWrapper from '../Layout/ContentsWrapper';
import { useMetaStore } from '../../store';
import { shape } from 'airbnb-prop-types';
import { showType } from '../../helpers/proptypes';

const ShowPage = ({
  pageContext: {
    show: {
      title,
      hosts,
      broadcast_start_time: broadcastStartTime,
      broadcast_end_time: broadcastEndTime,
      description_en: descriptionEn,
      description_fr: descriptionFr,
      mixcloud_slug: mixcloudSlug,
      image,
      tags,
      languages,
    },
    slug,
  },
}, ...pageData) => {
  // const setPlaybackSlug = usePlaybackStore(({ setSlug }) => setSlug);
  const startDay = format(new Date(broadcastStartTime), 'yyyy/M/d');
  const startDayPretty = format(new Date(broadcastStartTime), 'dd.MM.yy');
  const datestring = `${startDay} ${broadcastEndTime?.substring(0, 5)}`;
  const broadcastEnd = new Date(datestring);

  const pageTitle = `${title} – ${startDayPretty}`;
  const metaImg = getMetaImg(image, hosts);

  return (
    <Page title={{ en: pageTitle, fr: pageTitle }} metaImg={metaImg} data={pageData}>
      <ContentsWrapper
        css={`
          position: relative;
          padding: 0px;
        `}
      >
        <Show
          name={title}
          isLast
          hosts={hosts}
          startTime={new Date(broadcastStartTime)}
          endTime={broadcastEnd}
          image={image?.publicURL}
          isOpen
          mixcloudSlug={mixcloudSlug}
          description={{
            en: descriptionEn,
            fr: descriptionFr,
          }}
          isArchive={!!mixcloudSlug}
          isShowPage
          tags={tags}
          languages={languages}
        />
      </ContentsWrapper>
    </Page>
  );
};

const getMetaImg = (image, hosts) => {
  if (image?.ext === '.mp4' || !image?.publicURL) {
    return hosts.length ? hosts[0]?.image?.publicURL : '';
  }

  return image?.publicURL;
};

ShowPage.propTypes = {
  pageContext: shape({
    show: showType,
  }),
};

export default ShowPage;
